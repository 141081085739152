<template>
  <v-menu
    ref="date_picker"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="curValue"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :placeholder="$t(placeholder)"
        :label="label"
        readonly
        :disabled="disabled"
        dense
        outlined
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :hide-details="hideDetails"
        :clearable="clearable"
        @click:clear="setMonth(null)"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="curValue"
      no-title
      scrollable
      :locale="locale"
      color="primary"
      :min="min"
      :max="max"
      :type="type"
      :range="range"
      :show-week="showWeek"
      :first-day-of-week="firstDayOfWeek"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn text color="primary" @click="setMonth(curValue)">
        {{ $t("buttons.ok") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppDatePicker",
  props: {
    value: {
      type: [String, Array, Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "date",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    showWeek: {
      type: Boolean,
      default: false,
    },
    firstDayOfWeek: {
      type: Number,
      default: 0,
    },
    dateFormat: {
      type: String,
      default: "DD-MM-YYYY",
    },
  },
  computed: {
    ...mapGetters({
      lang: "app/getLang",
    }),
    curValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    dateFormatType() {
      if (this.type === "month") return "MMM YYYY";
      return this.dateFormat;
    },
    dateFormatted: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.map((itm) => {
            return this.$moment(itm).format(this.dateFormatType);
          });
        }
        return this.value
          ? this.$moment(this.value).format(this.dateFormatType)
          : null;
      },
      set() {},
    },
    locale() {
      switch (this.lang) {
        case "en":
          return "en-GB";
        case "de":
          return "de-CH";
      }
      return "en-GB";
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    setMonth(val) {
      if (!val) this.curValue = null;
      this.$refs.date_picker.save(val);
      this.menu = false;
      this.$emit("submit", val);
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label.v-label--is-disabled {
  font-weight: 400;
  color: grey !important;
}
</style>
